import { PiFiles } from '@react-icons/all-files/pi/PiFiles';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { appRoutesObj } from '../../app-paths';
import PNULogo from '../../assets/images/pnu-logo.svg';
import {
  ColorsEnum,
  SizesEnum,
  VariantsEnum,
} from '../../core/enums/drones.enum';
import useIsMobileScreen from '../../core/helpers/use-is-mobile-screen.helpers';
import { Button } from '../common/Button';

export default function Header() {
  const { t } = useTranslation();
  const { isMobile } = useIsMobileScreen();
  const location = useLocation();
  const isRegistrationPage = location.pathname.includes('registration');
  const navigate = useNavigate();

  return (
    <nav className="z-[100] fixed top-8 left-0 right-0 mx-auto bg-black rounded-xl lg:w-full lg:max-w-7xl w-11/12">
      <div className="flex items-center justify-between rounded-xl w-full h-full p-4 lg:px-6">
        <Link to={appRoutesObj.getLandingPagePath()} className="block">
          <img
            src={PNULogo}
            alt="pnu-Logo"
            className={`cursor-pointer ${isMobile ? 'h-12' : 'h-16'}`}
            loading="lazy"
          />
        </Link>
        <Button
          color={ColorsEnum.Primary}
          size={SizesEnum.Small}
          variant={VariantsEnum.Outline}
          className={isMobile || isRegistrationPage ? 'hidden' : 'visible'}
          onClick={() => navigate(appRoutesObj.getRegistrationFormPagePath())}
        >
          <div className="flex items-center justify-center gap-2">
            {t('app.shared.register')}
            <PiFiles size={20} />
          </div>
        </Button>
      </div>
    </nav>
  );
}
