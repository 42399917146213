import AppRouting from './app.routing';
import ToastMessage from './components/common/toast-message';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import './App.scss';

function App() {
  return (
    <main>
      <div className="bg-[#0D0A01]">
        <div className="flex min-h-screen flex-col justify-start">
          <Header />
          <div className="min-h-[90vh]">
            <AppRouting />
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      <ToastMessage />
    </main>
  );
}

export default App;
