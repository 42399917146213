interface RouteUtilInterface {
  get404Path(): string;
  getForbiddenPath(): string;
  getLandingPagePath(): string;
}

export class BaseRouteUtil implements RouteUtilInterface {
  get404Path(): string {
    return '/404';
  }

  getForbiddenPath(): string {
    return '/forbidden';
  }

  getLandingPagePath(): string {
    return '/';
  }

  getLandingPageUrl(): string {
    return this.getLandingPagePath();
  }

  getRegistrationFormPagePath(): string {
    return this.getLandingPagePath() + 'registration';
  }

  getRegistrationFormPageUrl(): string {
    return this.getRegistrationFormPagePath();
  }
}

class AppRoutes extends BaseRouteUtil {
  [x: string]: any;
  public shared: SharedRoutes;

  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    super();
    this.shared = new SharedRoutes();
  }
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }
}

class SharedRoutes {}

export const appRoutesObj = AppRoutes.getInstance();
