import { useTranslation } from 'react-i18next';

import logo from '../../assets/images/pnu-white-logo.svg';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#0D0A01] lg:bg-footer-pattern bg-contain bg-footer-position bg-no-repeat mx-auto flex flex-col items-center justify-center gap-10 w-full px-9 py-6 lg:px-6">
      <img
        src={logo}
        alt={t('components.Footer.allRightsReserved')}
        className="h-16 w-auto"
      />
      <p className="text-center text-white">
        © {t('components.Footer.allRightsReserved')}
      </p>
    </footer>
  );
}
