import { lazy, Suspense } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { appRoutesObj } from './app-paths';

const LandingPage = lazy(() => import('./pages/landing-page/landing-page'));

const ApplyPageContainer = lazy(
  () => import('./containers/apply-page-container/apply-page-container'),
);

function AppRouting() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          key="home"
          path={appRoutesObj.getLandingPagePath()}
          element={<LandingPage />}
          index
        />
        <Route
          key="apply"
          path={appRoutesObj.getRegistrationFormPagePath()}
          element={<ApplyPageContainer />}
        />

        <Route
          key="notDefined"
          path="*"
          element={<Navigate to={appRoutesObj.getLandingPagePath()} />}
        />
      </Routes>
    </Suspense>
  );
}

export default AppRouting;
