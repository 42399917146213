export enum ColorsEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Success = 'success',
  Warning = 'warning',
  Accent = 'accent',
  Muted = 'muted',
  White = 'white',
}

export enum VariantsEnum {
  Filled = 'filled',
  Outline = 'outline',
  Muted = 'muted',
  Link = 'link',
  None = 'none',
  White = 'white',
}

export enum SizesEnum {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

export enum DirectionEnum {
  LTR = 'ltr',
  RTL = 'rtl',
}

export enum LanguagesEnum {
  EN = 'en',
  AR = 'ar',
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
